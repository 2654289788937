<template>
  <v-col :cols="formRight">
    <v-text-field
      :label="$t('common.component.address.zip')"
      :placeholder="$t('common.component.address.zip')"
      ref="refZip"
      v-model="data.zipcode"
      @input="onChangeZipCode"
    />
    <v-text-field
      :label="$t('common.component.address.city')"
      :placeholder="$t('common.component.address.city')"
      ref="refCity"
      v-model="data.city"
      :disabled="data.disabledCityAndState"
    />
    <v-text-field
      :label="$t('common.component.address.state')"
      :placeholder="$t('common.component.address.state')"
      ref="refState"
      v-model="data.street"
      :disabled="data.disabledCityAndState"
    />
    <v-text-field 
      :label="$t('common.component.address.addr1')"
      :placeholder="$t('common.component.address.addr1')"
      ref="refAddress1" 
      v-model="data.address1" 
    />
    <v-text-field 
      :label="$t('common.component.address.addr2')"
      :placeholder="$t('common.component.address.addr2')"
      ref="refAddress2" 
      v-model="data.address2" 
    />
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    data: {
      type: [Object, Array],
    },
    formLeft: {
      type: Number,
      default: 10,
    },
    formRight: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {};
  },
  methods: {
    async onChangeZipCode(e) {
      let val = e;
      console.log("onChangeZipcode val", val);
      if (val.length > 4) {
        let url = `https://ziptasticapi.com/${val}`;
        this.getApiDataQuery(url).then((a) => {
          console.log("onChangeZipCode a :", a);
          //zip 검색결과 없는경우
          if (a.data.error) {
            // this.$snackbar({ message: `test`, color: "warning" });
            this.$helper.showTostMessage("", a.data.error, "error");
            this.data.city = "";
            this.data.street = "";
            this.data.disabledCityAndState = false;
            return;
          }
          //검색결과 있는경우
          this.data.city = a.data.city;
          this.data.street = a.data.state;
          // this.data.disabledCityAndState = false;
          this.$refs.refAddress1.focus();
          console.log("this.userData", this.data);
        });
      } else {
        this.data.city = "";
        this.data.street = "";
        // this.data.disabledCityAndState = true;
      }
    },
    ...mapActions(["getApiDataQuery"]),
  },
};
</script>
<style lang="">
</style>