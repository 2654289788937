<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        Head Office
        {{
          headOfficeData && headOfficeData.headOffice_id > 0
            ? "UPDATE"
            : "REGISTRATION"
        }}
      </v-card-title>

      <HeadOfficeAddForm
        ref="refHeadOfficeAddForm"
        :headOfficeData="headOfficeData"
      />

      <BtnBox
        :dataId="headOfficeData.headOffice_id"
        :activated="headOfficeData.activated"
        @save="saveHeadOffice"
        @active="activeHeadOffice"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import HeadOfficeAddForm from "./form/HeadOfficeAddForm.vue";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    BtnBox,
    HeadOfficeAddForm,
  },
  data() {
    return {
      visible: false,
      requiredMsg: requireService.requiredFieldAlertMessage(),
      reqData: modelService.reqDataModel(),
      headOfficeData: modelService.headOfficeModel(),
    };
  },
  methods: {
    Open(headOfficeData) {
      console.log("headOfficeData", headOfficeData);
      this.visible = true;
      this.reqData.headOffice_id = headOfficeData.headOffice_id; // 처리 필요
      this.reqData.head_id = headOfficeData.headOffice_id;
      this.headOfficeData = headOfficeData;
      if (headOfficeData.headOffice_id > 0) this.getHeadOfficeData();

      setTimeout(() => {
        this.$refs.refHeadOfficeAddForm.$refs.refHeadOfficeName.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    activeHeadOffice(option) {
      this.headOfficeData.activated = option;
      this.saveAction(this.headOfficeData);
    },
    /** 데이터읽기  */
    async getHeadOfficeData() {
      this.reqData.endpoint = "headOfficeOne";
      await this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        let resData = res.data;
        this.headOfficeData = resData;
        console.log("this.headOfficeData", this.headOfficeData);
      });
    },
    /** 저장/수정/삭제  */
    async saveHeadOffice() {
      if (!this.$refs.refHeadOfficeAddForm.$refs.form.validate()) return;
      console.log("this.headOfficeData", this.headOfficeData);
      // return;
      const saveLbl =
        this.headOfficeData.headOffice_id > 0 ? "Update" : "Register";
      if (!confirm(`Would you like to ${saveLbl}?`)) return;
      this.saveAction(this.headOfficeData);
    },
    async saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "headOffice";
      await this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
</style>