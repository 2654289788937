<template>
  <v-card-text>
    <v-form ref="form">
      <v-row>
        <v-col>
          <v-text-field
            label="Head Office Name"
            ref="refHeadOfficeName"
            v-model="headOfficeData.officeName"
            :rules="[filedRules.required, filedRules.counter]"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="E-mail"
            ref="refEmail"
            v-model="headOfficeData.email"
            placeholder="* Email"
            :rules="[emailRules.required, emailRules.counter, emailRules.email]"
            required
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-text-field
            label="Eligible"
            ref="refEligible"
            v-model="headOfficeData.eligible"
            placeholder="* Eligible"
            :rules="[eligibleRules.required]"
            required
          />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-text-field
            label="Mobile"
            ref="refCell"
            v-model="headOfficeData.cell"
            placeholder="* Mobile"
            :rules="[cellRules.required, cellRules.min, cellRules.max]"
            required
          />
        </v-col>
      </v-row>

      <!-- 주소 -->
      <Address :data="headOfficeData && headOfficeData.address" />
    </v-form>
  </v-card-text>
</template>
<script>
import Address from "@/components/commonV2/ui/AddressListItem.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Address,
  },
  props: {
    headOfficeData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      emailRules: rulesService.emailRules(),
      eligibleRules: rulesService.eligibleRules(),
      cellRules: rulesService.cellRules(),
    };
  },
  methods: {},
};
</script>
<style lang="">
</style>